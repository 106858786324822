import React, { useState, useEffect } from "react";
import { Button, Card, Row, Col, Spinner, Form, Modal } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faPenToSquare } from "@fortawesome/free-solid-svg-icons";
import "./Products.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

function Products() {
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalDataCount, setTotalDataCount] = useState(0);
  const [dataPerPage] = useState(10);
  const [showModal, setShowModal] = useState(false);
  const [formData, setFormData] = useState({
    product_name: "",
    product_description: "",
    priceinINR: "",
    priceinUSD: "",
    priceinEUR: "",
    priceinAED: "",
    quantity: "",
    shipping: "",
    cod: "",
    refund: "",
    free_delivery: "",
    category: "",
    unit: "",
  });
  const navigate = useNavigate();

  const handleAddProductClick = () => {
    navigate("/AddProduct"); // Replace with your desired route
  };

  const fetchProducts = async (pageNo) => {
    setIsLoading(true);
    setError(null);

    try {
      // Corrected axios.post usage
      const response = await axios.post(
        "https://lunarsenterprises.com:3000/bhakshanangal/productlist",
        {
          page_no: pageNo,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      const result = response.data; // Access data correctly from response
      if (result.result) {
        setProducts(result.data);
        setTotalDataCount(result.total_data_count);
      } else {
        setError("Failed to fetch products.");
      }
    } catch (err) {
      setError("An error occurred while fetching data.");
    } finally {
      setIsLoading(false);
    }
  };



  const handleAddProduct = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    console.log("Attempting to add product...");
  
    try {
      const userId = localStorage.getItem("user_id");
      const userApiKey = localStorage.getItem("user_api_key");
  
      if (!userId || !userApiKey) {
        throw new Error("User ID or API Key is missing from local storage.");
      }
  
      // Corrected variable name: "data" instead of "formdata"
      const data = new FormData();
      
      // Append form data fields from formData state
      data.append("product_name", formData.product_name); // Product name
      data.append("product_description", formData.product_description); // Product description
      data.append("priceinINR", formData.priceinINR); // Price in INR
      data.append("priceinUSD", formData.priceinUSD); // Price in USD
      data.append("priceinEUR", formData.priceinEUR); // Price in EUR
      data.append("priceinAED", formData.priceinAED); // Price in AED
      data.append("quantity", formData.quantity); // Quantity available
      data.append("shipping", formData.shipping); // Shipping type
      data.append("cod", formData.cod); // Cash on Delivery option
      data.append("refund", formData.refund); // Refund option
      data.append("free_delivery", formData.free_delivery); // Free delivery option
      data.append("category", formData.category); // Product category
      data.append("unit", formData.unit); // Unit of measure
      
      // If there's an image file, append it to FormData
      if (formData.image && formData.image instanceof File) {
        data.append("image", formData.image); // Assuming formData.image is a File object
      }
  
      // Log the form data to check if everything is appended properly
      console.log("Form Data:", data);
  
      const response = await axios.post(
        "https://lunarsenterprises.com:3000/bhakshanangal/addproduct",
        data,
        {
          headers: {
            user_id: userId,
            api_key: userApiKey,
          },
        }
      );
  
      console.log("API Response:", response);
  
      if (response.data.result) {
        alert("Product added successfully!");
        setShowModal(false);
        fetchProducts(currentPage);
      } else {
        alert(response.data.message || "Failed to add product.");
      }
    } catch (err) {
      alert("An error occurred while adding the product.");
      console.error("Error while adding product:", err); // Log the error for debugging
    } finally {
      setIsLoading(false);
    }
  };
  
  const handleDeleteProduct = async (product_id) => {
    if (window.confirm("Are you sure you want to delete this product?")) {
      try {
        const userId = localStorage.getItem("user_id");
        const userApiKey = localStorage.getItem("user_api_key");

        if (!userId || !userApiKey) {
          throw new Error("User ID or API Key is missing from local storage.");
        }

        console.log("Deleting product with ID:", product_id);

        setIsLoading(true);

        const response = await axios.post(
          "https://lunarsenterprises.com:3000/bhakshanangal/delete-product",
          { product_id },
          {
            headers: {
              "Content-Type": "application/json",
              api_key: userApiKey,
              user_id: userId,
            },
          }
        );

        if (response.data.result) {
          alert("Product deleted successfully.");
          fetchProducts(currentPage); // Refresh the product list
        } else {
          alert(response.data.message || "Failed to delete product.");
        }
      } catch (err) {
        alert("An error occurred while deleting the product.");
        console.error("Delete Product Error:", err);
      } finally {
        setIsLoading(false);
      }
    }
  };

  useEffect(() => {
    fetchProducts(currentPage);
  }, [currentPage]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  return (
    <div>
      {/* <div className="dashboard-header">
        <h4 className="dashboard-heading">PRODUCTS</h4>
      </div> */}
      <div className="container">
        <div style={{ display: "flex", justifyContent: "flex-end", marginTop:"25px" }}>
        <Button className="add-product-btn " onClick={handleAddProductClick}>
      ADD PRODUCT
    </Button>
        </div>
      </div>

      {/* Add Product Modal */}
      {/* <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton>
          <Modal.Title>Add Product</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form onSubmit={handleAddProduct}>
            {Object.keys(formData).map((key) => (
              <Form.Group key={key} className="mb-3">
                <Form.Label>{key.replace(/_/g, " ")}</Form.Label>
                <Form.Control
                  type="text"
                  name={key}
                  value={formData[key]}
                  onChange={handleInputChange}
                  required
                />
              </Form.Group>
            ))}
            <Button className="add-product-btn w-100" variant="primary" type="submit" >
              Add Product
            </Button>
          </Form>
        </Modal.Body>
      </Modal> */}

      <div className="container mt-5">
        <Row xs={1} sm={2} md={3} lg={4} className="g-4">
          {products.map((product) => (
            <Col md={4} className="mb-4" key={product.product_id}>
              <Card className="card-size-adjust">
                <div className="card-image-container">
                  <Link to={`/EditProduct/${product.product_id}`}>
                    <FontAwesomeIcon className="icon-left" icon={faPenToSquare} />
                  </Link>
                  <img
                    src={`https://lunarsenterprises.com:3000/${product.image_file}`}
                    alt={product.product_name}
                    className="card-img-top"
                  />
                  <FontAwesomeIcon
                    className="icon-right"
                    icon={faTrash}
                    onClick={() => handleDeleteProduct(product.product_id)}
                  />
                </div>
                <Card.Body>
                  <Card.Title>{product.product_name}</Card.Title>
                  <Card.Text>₹{product.price}</Card.Text>
                  <Card.Text>
                    {product.quantity} {product.product_unit}
                  </Card.Text>
                </Card.Body>
              </Card>
            </Col>
          ))}
        </Row>
      </div>

      <div className="pagination-container mt-4">
        <Button
        className="button-number"
          variant="primary"
          disabled={currentPage === 1}
          onClick={() => setCurrentPage(currentPage - 1)}
        >
          Previous
        </Button>
        <span className="mx-3">
          Page {currentPage} of {Math.ceil(totalDataCount / dataPerPage)}
        </span>
        <Button
          variant="primary"
           className="button-number"
          disabled={currentPage === Math.ceil(totalDataCount / dataPerPage)}
          onClick={() => setCurrentPage(currentPage + 1)}
        >
          Next
        </Button>
      </div>
    </div>
  );
}

export default Products;
