import React, { useState, useEffect } from "react";
import "./AddProduct.css";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom"; // useParams to fetch productId from route params
import { toast, ToastContainer } from "react-toastify";

const EditProduct = () => {
  const navigate = useNavigate();
  const { productId } = useParams(); // Accessing the product_id from the URL
 
  console.log(productId, "Product ID on EditProduct page");

  const userId = localStorage.getItem("user_id");
      const userApiKey = localStorage.getItem("user_api_key");
  
  
  if (productId) {
    console.log(productId, "Product ID received");
  } else {
    console.log("No productId found in state.");  
  }
  const [productData, setProductData] = useState({
    product_name: "",
    product_description: "",
    priceinINR: "",
    priceinUSD: "",
    priceinEUR: "",
    priceinAED: "",
    quantity: "",
    shipping: 1,
    cod: 1,
    refund: 1,
    free_delivery: 1,
    category: "",
    product_unit: "",
    product_id: productId, // Assign productId from URL
    product_status: "instock",
    image: null,
  });
  const [categories, setCategories] = useState([]);
  const [exist, setExist] = useState("");

  useEffect(() => {
    axios
      .post("https://lunarsenterprises.com:3000/bhakshanangal/categorylist")
      .then((response) => {
        if (response.data && response.data.result) {
          console.log(response.data.list);
          
          setCategories(response.data.list); // Assuming 'data' contains the list of categories
        } else {
          console.error("Failed to fetch categories");
        }
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  useEffect(() => {
   
    console.log("Product ID:", productId);
    if (productId) {
      axios
        .post("https://lunarsenterprises.com:3000/bhakshanangal/productview", {
          product_id: productId, 
        })
        .then((response) => {
          console.log(response.data,"dddaaaattaaa");
          
          if (response.data && response.data.result) {
            setProductData(response.data.data[0]); 
            
          } else {
            console.error("Product not found");
          }
        })
        .catch((error) => {
          console.error("Error fetching product:", error);
        });
    }
  }, [productId]);




  const handleChange = (e) => {
    const { name, value } = e.target;
    setProductData({
      ...productData,
      [name]: value,
    });
  };


  console.log(productData,'productDataproductDataproductData');
  
  const handleCheckboxChange = (e) => {
    setProductData({
      ...productData,
      [e.target.name]: e.target.checked ? 1 : 0,
    });
  };

  const handleImageChange = (e) => {
    setProductData({
      ...productData,
      image: e.target.files[0],
    });
  };

  console.log(productData,"uuuuuuuuuuuuuuuuuuuuuuu");
  const handleSubmit = (e) => {
    
    e.preventDefault();
    const formData = new FormData();
    formData.append("product_name", productData.product_name);
    formData.append("product_description", productData.description);
    formData.append("priceinINR", productData.price);
    // formData.append("priceinUSD", productData.priceinUSD);
    // formData.append("priceinEUR", productData.priceinEUR);
    // formData.append("priceinAED", productData.priceinAED);
    formData.append("quantity", productData.quantity);
    // formData.append("shipping", productData.shipping);
    // formData.append("cod", productData.cod);
    // formData.append("refund", productData.refund);
    // formData.append("free_delivery", productData.free_delivery);
    formData.append("category", productData.category_name);
    formData.append("unit", productData.product_unit);

    // formData.append("product_id", productData.product_id);
    // formData.append("product_status", productData.product_status);

    if (productData.image) {
      formData.append("image", productData.image);
    }

    console.log(productData.category_name,'hhhhhhh');
    

    axios({
      url: "https://lunarsenterprises.com:3000/bhakshanangal/update-product",
      method: "POST",
      data: formData,
      headers: {
        Accept: "application/json",
        "Content-Type": "multipart/form-data",
        "user_id":userId ,
        "api_key":userApiKey

      },
    })
      .then((response) => {
        if (response.data.result === true) {
          navigate("/products");
          toast.success(response.data.message);
        } else {
          toast.error(response.data.message);
        }
      })
      .catch((error) => {
        console.error("Error updating product:", error);
        toast.error("Failed to update product.");
      });
  };

  return (
    <div className="add-product-form-container">
      <ToastContainer />
      <h2>Edit Product</h2>
      <form onSubmit={handleSubmit}>
        <div>
          <label htmlFor="product_name" className="add-product-form-label">
            Product Name:
          </label>
          <input
            type="text"
            id="product_name"
            name="product_name"
            value={productData?.product_name}
            onChange={handleChange}
            className="add-product-input"
          />
        </div>
        <div>
          <label
            htmlFor="product_description"
            className="add-product-form-label"
          >
            Product Description:
          </label>
          <textarea
            id="product_description"
            name="product_description"
            value={productData?.description}
            onChange={handleChange}
            className="add-product-textarea"
          />
        </div>
        <div>
          <label htmlFor="priceinINR" className="add-product-form-label">
            Price in INR:
          </label>
          <input
            type="number"
            id="priceinINR"
            name="priceinINR"
            value={productData?.price}
            onChange={handleChange}
            className="add-product-input"
          />
        </div>
        {/* <div>
          <label htmlFor="priceinUSD" className="add-product-form-label">
            Price in USD:
          </label>
          <input
            type="number"
            id="priceinUSD"
            name="priceinUSD"
            value={productData?.priceinUSD}
            onChange={handleChange}
            className="add-product-input"
          />
        </div> */}
        {/* <div>
          <label htmlFor="priceinEUR" className="add-product-form-label">
            Price in EUR:
          </label>
          <input
            type="number"
            id="priceinEUR"
            name="priceinEUR"
            value={productData?.priceinEUR}
            onChange={handleChange}
            className="add-product-input"
          />
        </div> */}
        {/* <div>
          <label htmlFor="priceinAED" className="add-product-form-label">
            Price in AED:
          </label>
          <input
            type="number"
            id="priceinAED"
            name="priceinAED"
            value={productData?.priceinAED}
            onChange={handleChange}
            className="add-product-input"
          />
        </div> */}
        <div>
          <label htmlFor="quantity" className="add-product-form-label">
            Quantity:
          </label>
          <input
            type="number"
            id="quantity"
            name="quantity"
            value={productData?.quantity}
            onChange={handleChange}
            className="add-product-input"
          />
        </div>

        <div>
          <label htmlFor="shipping" className="add-product-form-label">
            Shipping:
          </label>
          <input
            type="checkbox"
            id="shipping"
            name="shipping"
            checked={productData?.shipping === 1}
            onChange={handleCheckboxChange}
            className="add-product-checkbox"
          />
        </div>
        <div>
          <label htmlFor="cod" className="add-product-form-label">
            COD Available:
          </label>
          <input
            type="checkbox"
            id="cod"
            name="cod"
            checked={productData?.cod === 1}
            onChange={handleCheckboxChange}
            className="add-product-checkbox"
          />
        </div>
        <div>
          <label htmlFor="refund" className="add-product-form-label">
            Refund Policy:
          </label>
          <input
            type="checkbox"
            id="refund"
            name="refund"
            checked={productData?.refund === 1}
            onChange={handleCheckboxChange}
            className="add-product-checkbox"
          />
        </div>
        <div>
          <label htmlFor="free_delivery" className="add-product-form-label">
            Free Delivery:
          </label>
          <input
            type="checkbox"
            id="free_delivery"
            name="free_delivery"
            checked={productData?.free_delivery === 1}
            onChange={handleCheckboxChange}
            className="add-product-checkbox"
          />
        </div>
        <div>
          <label htmlFor="category_name" className="add-product-form-label">
            Category:
          </label>
          <select
            id="category_name"
            name="category_name"
            value={productData?.category_name}
            onChange={handleChange}
            className="add-product-input"
          >
            {categories && categories.length > 0 ? (
              categories.map((category) => (
                <option key={category.category_id} value={category.category_id}>
                  {category.category_id}
                </option>
              ))
            ) : (
              <option disabled>No categories available</option>
            )}
          </select>
        </div>
        <div>
          <label htmlFor="product_unit" className="add-product-form-label">
            Unit:
          </label>
          <select
            id="product_unit"
            name="product_unit"
            value={productData?.product_unit}
            onChange={handleChange}
            className="add-product-input"
          >
             <option value="">Select Unit</option>
            <option value="gm">Gram</option>
            <option value="kg">Kilogram</option>
            <option value="ltr">Liter</option>
          </select>
        </div>
        <div>
          <label htmlFor="image" className="add-product-form-label">
            Product Image:
          </label>
          <input
            type="file"
            id="image"
            name="image"
            onChange={handleImageChange}
            className="add-product-input"
          />
          {exist && (
            <div>
              <img
                src={`https://lunarsenterprises.com:3000/${exist}`}
                alt="Product"
                className="existing-image"
              />
            </div>
          )}
        </div>
        <div>
          <button type="submit" className="add-product-button w-100">
            Update Product
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditProduct;
