import React, { useEffect, useState } from "react";
import axios from "axios";
import "./Dashboard.css";
import { Spinner, Alert, Table, Button, Form } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import banner from "../../Assets/banner.jpg"

function Dashboard() {
  const [orders, setOrders] = useState([]);
  const [filteredOrders, setFilteredOrders] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showDatePicker, setShowDatePicker] = useState(false);
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const userId = localStorage.getItem("user_id");
  const userApiKey = localStorage.getItem("user_api_key");

  useEffect(() => {
    if (!userId || !userApiKey) {
      setError("User is not authenticated.");
      setLoading(false);
      return;
    }

    const fetchOrders = async () => {
      try {
        const response = await axios.post(
          "https://lunarsenterprises.com:3000/bhakshanangal/orderlist",
          {},
          {
            headers: {
              user_id: userId,
              api_key: userApiKey,
            },
          }
        );

        if (response.data.result) {
          setOrders(response.data.data);
          setFilteredOrders(response.data.data); // Initially set filtered orders to all orders
        } else {
          setError(response.data.message || "Failed to fetch orders.");
        }
      } catch (err) {
        setError("Failed to fetch orders. Please try again later.");
        console.error(err);
      } finally {
        setLoading(false);
      }
    };

    fetchOrders();
  }, [userId, userApiKey]);

  const handleFilter = () => {
    if (startDate && endDate) {
      const start = new Date(startDate).setHours(0, 0, 0, 0);
      const end = new Date(endDate).setHours(23, 59, 59, 999);
      const filtered = orders.filter((order) => {
        const orderDate = new Date(order.created_at).getTime();
        return orderDate >= start && orderDate <= end;
      });
      setFilteredOrders(filtered);
    } else {
      setFilteredOrders(orders); // Reset to all orders if no dates are selected
    }
  };

  return (
    <div>
    <div className="mt-5">
    <img className="dashboard-image-banner" src={banner} alt="Banner" />
  </div>
    <div className="container">
     

      <div className="dashboard-first">
        <div className="column">
          <h4 className="dashboard-details">NUMBER OF USERS</h4>
          <p className="data-value">1,234</p>
        </div>
        <div className="column">
          <h4 className="dashboard-details">ORDERS</h4>
          <p className="data-value">{filteredOrders.length}</p>
        </div>
        <div className="column">
          <h4 className="dashboard-details">SALES</h4>
          <p className="data-value">$12,345</p>
        </div>
      </div>

      <div className="dashboard-header">
        <h5>ORDER HISTORY</h5>
      </div>

      <div className="d-flex justify-content-end align-items-center p-3 filter-container">
        {showDatePicker && (
          <>
            <Form.Control
              type="date"
              className="me-2 date-picker"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
            <Form.Control
              type="date"
              className="me-2 date-picker"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
            <Button className="me-2" variant="success" onClick={handleFilter}>
              Apply
            </Button>
          </>
        )}
        <Button
          className="filter-button"
          variant="primary"
          onClick={() => setShowDatePicker(!showDatePicker)}
          style={{ marginRight: "10px" }} // Adjust the space here
        >
          {showDatePicker ? "Close" : "Filter"}
        </Button>
        <Button className="filter-button" variant="primary">
          <FontAwesomeIcon icon={faDownload} />
        </Button>
      </div>

      {loading ? (
        <div className="text-center">
          <Spinner animation="border" />
          <p>Loading orders...</p>
        </div>
      ) : error ? (
        <Alert variant="danger">{error}</Alert>
      ) : filteredOrders.length > 0 ? (
        <div className="table-responsive-container">
          <Table className="custom-table responsive" responsive>
            <thead>
              <tr>
                <th>User Name</th>
                <th>Date & Time</th>
                <th>Product</th>
                <th>Delivery Address</th>
                <th>Amount</th>
                <th>Payment Type</th>
                
                <th>Payment Status</th>
                <th>Delivery Status</th>
              </tr>
            </thead>
            <tbody>
              {filteredOrders.map((order, index) => (
                <tr
                  key={index}
                  className={index % 2 === 0 ? "white-row" : "yellow-row"}
                >
                  <td>{order.user_name}</td>
                  <td>{new Date(order.created_at).toLocaleString()}</td>
                  <td>
                    {order.product.map((prod, i) => (
                      <div key={i}>{prod.product_name}</div>
                    ))}
                  </td>
                  <td>
                    {order.address
                      ? `${order.address.address_building_name}, ${order.address.address_area_name}, ${order.address.address_city}, ${order.address.address_state}`
                      : "N/A"}
                  </td>
                  <td>
                    {order.product.reduce((sum, prod) => sum + prod.price, 0)}
                  </td>
                  <td>{order.order_payment_method}</td>
                  
                  <td>{order.order_status}</td>
                  <td>{order.delivery_mode_status}</td>
                </tr>
              ))}
            </tbody>
          </Table>
        </div>
      ) : (
        <p>No orders found for the selected date range.</p>
      )}
    </div>
    </div>
  );
}

export default Dashboard;
