import React, { useState } from "react";
import { Navbar, Nav, Form, Offcanvas } from "react-bootstrap";
import {
  FaBell,
  FaEnvelope,
  FaUser,
  FaHome,
  FaTable,
  FaSignOutAlt,
  FaWrench,
  FaChartLine,
} from "react-icons/fa";
import "./Navbar.css";
import { useNavigate } from "react-router-dom";
import { FaPeopleGroup } from "react-icons/fa6";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import logo from "../../Assets/croplogo.png";

function Navbars() {
  const Navigate = useNavigate();
  const [showSidebar, setShowSidebar] = useState(false);

  const toggleSidebar = () => setShowSidebar(!showSidebar);
  const handleLogOut = () => {
    localStorage.removeItem("isLoggedIn");
    Navigate("/");
  };
  return (
    <>
      <ToastContainer autoClose={3000} />
      <Navbar expand="lg" className="navbar-design shadow-sm px-3">
        <Navbar.Toggle
          aria-controls="navbarResponsive"
          onClick={toggleSidebar}
          className="me-2"
        />
        <Navbar.Collapse id="navbarResponsive" className="flex-grow-1">
          <Form
            className="d-none d-lg-flex ms-auto me-3"
            style={{ maxWidth: "300px" }}
          ></Form>
          <Nav className="ms-auto d-none d-lg-flex align-items-center justify-content-between w-100">
            {/* Logo on the left */}
            <div className="d-flex align-items-center">
              <img
                className="bhakshanagal-logo"
                src={logo}
                alt="description of image"
              />
            </div>

            {/* Center heading */}
            <div className="text-center flex-grow-1">
              <h4 className="m-0">BHAKSHANANGAL FOODS PRIVATE LIMITED</h4>
            </div>

            {/* Right side user info */}
            <Nav.Link href="#" className="d-flex align-items-center">
              <FaUser className="me-1" />
              <span>HI ADMIN</span>
            </Nav.Link>
          </Nav>
        </Navbar.Collapse>
      </Navbar>

      {/* Sidebar Content in Offcanvas for Mobile */}
      <Offcanvas show={showSidebar} onHide={toggleSidebar} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Menu</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Nav className="flex-column">
            <Nav.Link
              href="/dashboard"
              className="text-dark d-flex align-items-center"
            >
              <FaHome className="sidebar-icon" />
              <span className="ms-2">Dashboard</span>
            </Nav.Link>
            <Nav.Link
              href="/products"
              className="text-dark d-flex align-items-center"
            >
              <FaTable className="sidebar-icon" />
              <span className="ms-2">Products</span>
            </Nav.Link>
            <Nav.Link
              href="/userlist"
              className="text-dark d-flex align-items-center"
            >
              <FaPeopleGroup className="sidebar-icon" />
              <span className="ms-2">UserList</span>
            </Nav.Link>
            <Nav.Link
              href="/EnquiryList"
              className="text-dark d-flex align-items-center"
            >
              <FaUser className="sidebar-icon" />
              <span className="ms-2">Enquiry Forms</span>
            </Nav.Link>
            {/* <Nav.Link
              href="#charts"
              className="text-dark d-flex align-items-center"
            >
              <FaChartLine className="sidebar-icon" />
              <span className="ms-2">Charts</span>
            </Nav.Link> */}
            <Nav.Link
              onClick={handleLogOut}
              className="text-dark d-flex align-items-center"
            >
              <FaSignOutAlt className="sidebar-icon" />
              <span className="ms-2">Logout</span>
            </Nav.Link>
          </Nav>
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Navbars;
