import React, { useState } from "react";
import "./Login.css";
import { Form, Button, Container, Row, Col } from "react-bootstrap";
import log from "../../Assets/Login.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const Login = () => {
  const [formData, setFormData] = useState({
    device_id: "AR01QW4561255",
    device_os: "android", // default value
    device_token: "5446",
    password: "",
    email: "",
    app_version: "2", // default value
  });
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");
  const navigate = useNavigate();

  // Handle input change
  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send a POST request to the backend login API
      const response = await axios.post(
        "https://lunarsenterprises.com:3000/bhakshanangal/login",
        formData
      );

      if (response.data.result === true) {
        // On successful login, show success message and user data
        setMessage(response.data.message);
        console.log("User Info:", response.data);
        localStorage.setItem("user_id", response.data.user_id);
        localStorage.setItem("user_api_key", response.data.user_api_key);

        // Store the logged-in state in local storage
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("isLoggedIn", true);
        navigate("/dashboard");
      } else {
        // On failure, show error message
        setError(response.data.message);
      }
    } catch (err) {
      // Handle any errors from the API call
      setError("An error occurred. Please try again later.");
      console.error(err);
    }
  };

  return (
    <>
      <Container
        className="d-flex justify-content-center align-items-center"
        style={{ minHeight: "100vh" }}
      >
        <Row className="w-100">
          {/* First Column */}
          <Col md={6} className="d-none d-md-block">
            <img className="login-page-image" src={log} alt="Login" />
          </Col>

          {/* Second Column */}
          <Col
            md={6}
            className="d-flex flex-column justify-content-center align-items-center"
          >
            <div style={{ width: "100%" }}>
              <h4 className="text-center mb-4">
                Welcome back to{" "}
                <span className="login-headline"> Bhakshanangal</span>
              </h4>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="formEmail" className="mb-3">
                  <Form.Label>Email ID</Form.Label>
                  <Form.Control
                    className="form-writing"
                    type="email"
                    placeholder="Enter your email"
                    name="email"
                    value={formData.email}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <Form.Group controlId="formPassword" className="mb-3">
                  <Form.Label>Password</Form.Label>
                  <Form.Control
                    className="form-writing"
                    type="password"
                    placeholder="Enter your password"
                    name="password"
                    value={formData.password}
                    onChange={handleInputChange}
                    required
                  />
                </Form.Group>
                <div className="d-flex justify-content-between mb-3">
                  <Button
                    className="login-button w-100"
                    variant="primary"
                    type="submit"
                  >
                    Log In
                  </Button>
                </div>
                <div className="d-flex justify-content-end mb-3">
                  <Button
                    href="/otp"
                    className="login-forget-page"
                    variant="link"
                    style={{ textDecoration: "none", padding: 0 }}
                  >
                    
                    Forget Password?
                   
                  </Button>
                </div>
              </Form>

              {/* Display success or error message */}
              {message && <div className="alert alert-success">{message}</div>}
              {error && <div className="alert alert-danger">{error}</div>}
            </div>
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Login;
