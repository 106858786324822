import React from 'react';
import "./EnquiryList.css";

function Enquiry() {
  const enquiries = [
    { name: 'John Doe', email: 'john@example.com', question: 'What is the price of the product?' },
    { name: 'Jane Smith', email: 'jane@example.com', question: 'Do you offer international shipping?' },
    { name: 'Sam Wilson', email: 'sam@example.com', question: 'Can I customize the order?' },
  ];

  return (
    <>
      <div className="dashboard-header">
        <h4 className="dashboard-heading">ENQUIRIES</h4>
      </div>
      <div className="enquiry-table">
        <div className="container">
        <table className="table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Enquiry Question</th>
            </tr>
          </thead>
          <tbody>
            {enquiries.map((enquiry, index) => (
              <tr key={index}>
                <td>{enquiry.name}</td>
                <td>{enquiry.email}</td>
                <td>{enquiry.question}</td>
              </tr>
            ))}
          </tbody>
        </table>
        </div>
      </div>
    </>
  );
}

export default Enquiry;
